import React, { Suspense, useContext } from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./assets/styles/custom.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import Loader from "./components/Loader";
import ScrollToTop from "./components/ScrollToTop";
import { AppContextProvider } from "./context/AppContext";
import { AuthContext, AuthProvider } from "./context/AuthContext";
import SignIn from "./pages/Auth/SignIn";
import OtpIndex from "./pages/Auth/Otp";
import VerifiedIndex from "./pages/Auth/VerifiedIndex";
import Home from "./pages/Auth/Home";
import HomeIndex from "./pages/Home/Index";
import WalletIndex from "./pages/Wallet/Index";
import AvailableCurrencies from "./pages/Available Currencies/Index";
import PrivateRoute from "./components/PrivateRoute";
import Error from "./pages/Error";
import ProfileIndex from "./pages/My Profile/Index";
import Index from "./pages/My Profile/ChangePassword";
import HelpIndex from "./pages/HelpSupport";
import FaqIndex from "./pages/Faq";
import AboutIndex from "./pages/AboutUs";
import ContentIndex from "./pages/Content";

window.Buffer = window.Buffer || require("buffer").Buffer;
function App() {
  return (
    <AuthProvider>
      <AppContextProvider>
        <Suspense fallback={<Loader />}>
          <BrowserRouter>
            <ScrollToTop />
            <ToastContainer closeOnClick={false} />
            <AppRoutes />
          </BrowserRouter>
        </Suspense>
      </AppContextProvider>
    </AuthProvider>
  );
}

const AppRoutes = () => {
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/login" element={<SignIn />} />
      <Route path="/otp" element={<OtpIndex />} />
      <Route path="/verified" element={<VerifiedIndex />} />

      <Route
        path="/"
        element={
          <PrivateRoute>
            <Outlet />
          </PrivateRoute>
        }
      >
        <Route path="/dashboard" element={<HomeIndex />} />
        <Route path="/wallet" element={<WalletIndex />} />
        <Route path="/available-currencies" element={<AvailableCurrencies />} />
        <Route path="/my-profile" element={<ProfileIndex />} />
        <Route path="/change-password" element={<Index />} />
        <Route path="/help-support" element={<HelpIndex />} />
        <Route path="/faq" element={<FaqIndex />} />
        <Route path="/about" element={<AboutIndex />} />
        <Route path="/content" element={<ContentIndex />} />

      </Route>

      <Route path="/error" element={<Error />} />
    </Routes>
  );
};

export default App;
