import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Layout,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Modal,
  Checkbox,
} from "antd";
import Logo from "../../assets/images/logo.svg";
import useRequest from "../../hooks/useRequest";
import useFirebase from "../../hooks/useFirebase";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import encryptDecrypt from "../../helper/encryptDecrypt";
import { InputOTP } from "antd-input-otp";
import { Container } from "react-bootstrap";

const { Title } = Typography;

const SignIn = () => {
  const [loading, setLoading] = useState(false);
  const [passwordLoading, setPasswordLoading] = useState(false);
  const { request } = useRequest();
  const [resetModal, setResetModal] = useState(false);
  const [email, setEmail] = useState();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [twoFA, setTwoFA] = useState(false);
  const [otpModal, setOtpModal] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const { fcmToken } = useFirebase();

  const [qrCode, setQrCode] = useState("");
  const [secret, setSecret] = useState("");
  const [userId, setUserId] = useState("");

  const handleCancel = () => {
    setIsModalOpen(false);
    setOtpModal(false);
  };

  const handleVerifyOTP = (values) => {
    const { otp } = values;
    setVerifyLoading(true);
    request({
      url: apiPath.verifyOTP,
      method: "POST",
      data: { email, otp: otp.join("") },
      onSuccess: (data) => {
        setVerifyLoading(false);
        setOtpModal(false);
        setResetModal(true);
        console.log(data);
      },
      onError: (err) => {
        setVerifyLoading(false);
        console.log(err);
      },
    });
  };

  const handleResetPassword = (email) => {
    setResetLoading(true);
    let payload = {};
    payload.email = email.email;
    request({
      url: apiPath.forgotPassword,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        var emailResetEncrypt = encryptDecrypt.encryptEmail(email.email);
        localStorage.setItem("UGwdfdR2uHMM24N", emailResetEncrypt);
        setResetLoading(false);
        ShowToast(data.message, Severty.SUCCESS);
        setOtpModal(true);
        setIsModalOpen(false);
        setEmail(email.email);
      },
      onError: (error) => {
        setResetLoading(false);
        ShowToast(error.response.data.message, Severty.ERROR);
      },
    });
  };

  const handleReset = (values) => {
    const { new_password } = values;
    let payload = { email, password: new_password };
    setPasswordLoading(true);
    request({
      url: apiPath.resetPassword,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setPasswordLoading(false);
        ShowToast(data.message, Severty.SUCCESS);
        setResetModal(false);
      },
      onError: (error) => {
        setPasswordLoading(false);
        ShowToast(error.response.data.message, Severty.ERROR);
      },
    });
  };

  const onSubmit = (values) => {
    const { email, password } = values;

    if (!email)
      return ShowToast("Please enter email to sign in", Severty.ERROR);

    const payload = {
      email,
      password,
      device_token: fcmToken,
      device_type: "web",
    };

    setLoading(true);
    request({
      url: apiPath.login,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.statusText == "true") {
          var emailEncrypt = encryptDecrypt.encryptEmail(values.email);
          var passwordEncrypt = encryptDecrypt.encryptPassword(values.password);

          if (rememberMe?.target?.checked === true || rememberMe === true) {
            localStorage.setItem("rememberMe", true);
            localStorage.setItem("ouUsippetc8S4Ry", passwordEncrypt);
          } else {
            localStorage.removeItem("rememberMe");
            localStorage.removeItem("ykmCe2AYEFTHobn");
            localStorage.removeItem("ouUsippetc8S4Ry");
          }

          localStorage.setItem("ykmCe2AYEFTHobn", emailEncrypt);
          ShowToast(data.message, Severty.SUCCESS);
          if(!data?.data?.twoFactorVerified){
            setTwoFA(true);
            generate2FA(data?.data?.id);
            setUserId(data?.data?.id);
          } else {
            navigate(`/otp`, { state: { id: data?.data?.id } })
          }
          // setTimeout(() => navigate(`/factor-auth/${data?.data?.id}`), 200);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error?.response?.data?.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    const savedEmail = localStorage.getItem("ykmCe2AYEFTHobn");
    const savedPassword = localStorage.getItem("ouUsippetc8S4Ry");

    if (savedEmail && savedPassword) {
      var originalEmail = encryptDecrypt.decryptEmail(savedEmail);
      var originalPassword = encryptDecrypt.decryptEmail(savedPassword);

      form.setFieldsValue({
        email: originalEmail,
        password: originalPassword,
        remember: true,
      });
      setRememberMe(true);
    } else {
      setRememberMe(false);
    }
  }, []);

  const generate2FA = (id) => {
    request({
      url: apiPath.generate2FA,
      method: "POST",
      data: { id },
      onSuccess: (data) => {
        console.log(data);
        setQrCode(data.data.qrCode);
        setSecret(data.data.secret);
      },
    });
  };

  return (
    <>
      <Layout className="layout-default layout-signin">
        <Container className="signin">
          <Row className=" align-items-center">
            <Col span={24}>
              <div className="header">
                <img onClick={() => navigate("/")} src={Logo} alt="" />
              </div>
            </Col>
            <Col span={24}>
              <div className="signup-logo ">
                {/* <Button
                  className="prev-pagebtn mt-2"
                  type="button"
                  onClick={() => navigate("/home")}
                  htmlType="submit"
                >
                  <ArrowLeftOutlined />  Back
                </Button> */}
              </div>
            </Col>
          </Row>
        </Container>
        <div className="signin-box">
          <Row justify="space-around">
            <Col xs={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }}>
              <div className="h-sign-menu">
                {/* <img src={LoginLogo} /> */}

                <Title
                  className="tital-text-sign mb-15"
                  onClick={() => navigate("/")}
                >
                  Login
                </Title>
                <p>Please login to continue our website</p>
              </div>
              <div className="signup-form">
                <Form
                  form={form}
                  onFinish={onSubmit}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                  className="row-col"
                >
                  <Form.Item
                    className="username-form"
                    label="Email Address"
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "Please enter a valid email address!",
                      },
                      {
                        required: true,
                        message: "Please enter email!",
                      },
                    ]}
                  >
                    <Input
                      className="form-of-type"
                      placeholder="Enter Email ID"
                    />
                  </Form.Item>

                  <Form.Item
                    className="username-form"
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please enter password!",
                      },
                    ]}
                  >
                    <Input.Password
                      className="form-of-type"
                      placeholder="Password"
                    />
                  </Form.Item>

                  <div className="forgot-pass">
                    <Form.Item
                      name="remember"
                      className="remember-type-text"
                      valuePropName="checked"
                    >
                      <Checkbox
                        checked={rememberMe}
                        className="rem-check"
                        onChange={(e) => {
                          setRememberMe(e.target.checked);
                        }}
                      ></Checkbox>
                      Remember me
                    </Form.Item>
                    <div className="user-dirct">
                      <span className="ac-create">
                        <Link onClick={() => setIsModalOpen(true)}>
                          Forgot password?
                        </Link>
                      </span>
                    </div>
                  </div>
                  <Form.Item className="signin-footer ">
                    <Button
                      className="submit-button "
                      type="submit"
                      htmlType="submit"
                      loading={loading}
                      // onClick={() => navigate("/")}
                    >
                      Sign In
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </Layout>

      {/* Forgot Password Modal */}
      <Modal
        className="tab_modal"
        open={isModalOpen}
        title="Forgot Password"
        okText="Send OTP"
        onCancel={handleCancel}
        okButtonProps={{
          form: "forget-password-form",
          htmlType: "submit",
          loading: resetLoading,
        }}
      >
        <Form
          id="forget-password-form"
          onFinish={handleResetPassword}
          layout="vertical"
        >
          <Form.Item
            className="username-form"
            label="Email Address"
            name="email"
            rules={[
              {
                type: "email",
                message: "Please enter a valid email address!",
              },
              {
                max: 255,
                message: "Email address not more than 255 characters!",
              },
              {
                required: true,
                message: "Please enter your email!",
              },
            ]}
          >
            <Input
              className="form-of-type"
              autoComplete="off"
              placeholder="Enter Email Address"
            />
          </Form.Item>
        </Form>
      </Modal>

      {/* OTP Verification Modal */}
      <Modal
        className="tab_modal"
        open={otpModal}
        title="Verify OTP"
        okText="Verify"
        onCancel={handleCancel}
        okButtonProps={{
          form: "verify-otp-form",
          htmlType: "submit",
          loading: verifyLoading,
        }}
      >
        <Form id="verify-otp-form" onFinish={handleVerifyOTP} layout="vertical">
          <Form.Item
            name="otp"
            className="otp-form"
            rules={[{ required: true, message: "Please enter the OTP" }]}
          >
            <InputOTP className="form-of-type" length={4} inputType="numeric" />
          </Form.Item>
        </Form>
      </Modal>

      {/* OTP Verification Modal */}
      <Modal
        className="tab_modal"
        open={resetModal}
        title="Reset Password"
        okText="Reset"
        onCancel={() => {
          setResetModal(false);
        }}
        okButtonProps={{
          form: "reset-pass-form",
          htmlType: "submit",
          loading: resetLoading,
        }}
      >
        <Form id="reset-pass-form" onFinish={handleReset} layout="vertical">
          <Form.Item
            className="username-form"
            label=""
            name="new_password"
            rules={[
              {
                required: true,
                message: "Please Enter New Password!",
              },
            ]}
          >
            <Input.Password
              className="form-of-type"
              autoComplete="off"
              placeholder="Enter New Password"
            />
          </Form.Item>
          <Form.Item
            className="username-form"
            label=""
            name="confirm_password"
            dependencies={["new_password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please enter the confirm password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("new_password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Password that you entered doesn't match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password
              className="form-of-type"
              autoComplete="off"
              placeholder="Enter Confirm Password"
            />
          </Form.Item>
        </Form>
      </Modal>

      {/* 2 Factor Auth Modal */}
      <Modal
        open={twoFA}
        onCancel={() => setTwoFA(false)}
        footer={null}
        centered
        title="Setup Two-Factor Authentication"
        width={600}
        className="main-new-scanner-qrcode"
      >
        <div className="main-new-scanner">
          <div className="main-new-scanner-card">
            <p>Scan the QR code below with Google Authenticator:</p>
            {qrCode && (
              <img
                src={qrCode}
                alt="QR Code"
                width={200}
                height={200}
                style={{ margin: "20px 0" }}
              />
            )}
            <p>
              Or manually enter the code: <strong>{secret}</strong>
            </p>
            <Button
            onClick={() => {
              navigate(`/otp`, { state: { id: userId } });
            }}
            className="submit-button "
                      type="submit"
                      htmlType="submit"
          >
            Continue
          </Button>
          </div>

        
        </div>
      </Modal>
    </>
  );
};

export default SignIn;
