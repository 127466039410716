import {
  Card,
  Col,
  Row,
  Tabs,
  Table,
  Button,
  Input,
  Modal,
  Form,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import SectionWrapper from "../../components/SectionWrapper";
import WalletIcon from "../../assets/images/ion_wallet.svg";
import useRequest from "../../hooks/useRequest";
import apiPath from "../../constants/apiPath";
import { Severty, ShowToast } from "../../helper/toast";
import ExchangeIcon from "../../assets/images/exchange.png";
import moment from "moment";
import { useAppContext } from "../../context/AppContext";
import { Option } from "antd/lib/mentions";

const { TabPane } = Tabs;

const columns = [
  {
    title: "Order ID",
    dataIndex: "id",
    key: "id",
    render: (_, { order_id }) => <span color="white">#{order_id || "-"}</span>,
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
    render: (_, { quantity }) => {
      return <span>{quantity || 0} KG</span>;
    },
  },
  {
    title: "Quality",
    dataIndex: "quality",
    key: "quality",
    render: (_, { quality }) => {
      return <span>{quality || "-"}</span>;
    },
  },
  {
    title: "Currency",
    dataIndex: "currency",
    key: "currency",
    render: (_, { currency }) => {
      return <span>{currency}</span>;
    },
  },
  {
    title: "Booked Price",
    dataIndex: "book_amount",
    key: "book_amount",
    render: (_, { book_amount }) => {
      return (
        <span>
          {book_amount?.toFixed(2)}
        </span>
      );
    },
  },
  {
    title: "Total Amount",
    dataIndex: "amount",
    key: "amount",
    render: (_, { amount }) => {
      return (
        <span>
          {amount?.toFixed(2)}
        </span>
      );
    },
  },
  // {
  //   title: "Status",
  //   dataIndex: "status",
  //   key: "status",
  //   render: (_, { status }) => {
  //     return <span className="text-uppercase">{status || "-"}</span>;
  //   },
  // },
  {
    title: "Date & Time",
    dataIndex: "date",
    key: "date",
    render: (_, { created_at }) => {
      return (
        <span>
          {moment(created_at).format("ll")},{" "}
          {moment(created_at).format("hh:mm A")}
        </span>
      );
    },
  },
];

const deposits = [
  {
    title: "Transaction ID",
    dataIndex: "id",
    key: "id",
    render: (_, { transaction_id }) => (
      <span color="white">#{transaction_id || "-"}</span>
    ),
  },
  {
    title: "Currency",
    dataIndex: "currency",
    key: "currency",
    render: (_, { wallet_id }) => {
      return <span>{wallet_id?.currency}</span>;
    },
  },
  {
    title: "Transaction Amount",
    dataIndex: "transaction_amount",
    key: "transaction_amount",
    render: (_, { transaction_amount, wallet_id }) => {
      return (
        <span>
          {transaction_amount?.toFixed(2) + " " + wallet_id?.currency || "-"}
        </span>
      );
    },
  },
  {
    title: "Transaction Type",
    dataIndex: "type",
    key: "type",
    render: (_, { type }) => {
      return <span className="text-uppercase">{type || "-"}</span>;
    },
  },
  {
    title: "Order ID",
    dataIndex: "type",
    key: "type",
    render: (_, { order_id }) => {
      return order_id?.order_id ? (
        <a className="text-uppercase">#{order_id?.order_id}</a>
      ) : (
        <span> - </span>
      );
    },
  },
  {
    title: "Date & Time",
    dataIndex: "date",
    key: "date",
    render: (_, { created_at }) => {
      return (
        <span>
          {moment(created_at).format("ll")},{" "}
          {moment(created_at).format("hh:mm A")}
        </span>
      );
    },
  },
];

function WalletIndex() {
  const [wallet, setWallet] = useState();
  const [loading, setLoading] = useState(false);
  const { pageHeading, setPageHeading } = useAppContext();
  const [selectedTab, setSelectedTab] = useState("order");
  const [transactions, setWalletTransactions] = useState([]);
  const [orders, setOrders] = useState([]);
  const [otherWallets, setOtherWallets] = useState([]);
  const [walletModal, setWalletModal] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState(wallet?._id ?? "");
  const { request } = useRequest();

  const handleTabChange = (status) => {
    setSelectedTab(status);
    console.log(status);
  };

  const fetchWalletInfo = (id) => {
    setLoading(true);
    request({
      url: apiPath.wallet + (id ? `/${id}` : ""),
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        if (data?.statusText == "true") {
          setWallet(data?.data?.wallet);
          setOtherWallets(data?.data?.otherWallets || []);
          localStorage.setItem("wallet", data?.data?.wallet?._id);
          // setWalletTransactions(data?.data?.transactions);
        } else {
          ShowToast(data?.message, Severty.ERROR);
        }
      },
      onError: (err) => {
        ShowToast(err?.error?.response?.message, Severty.ERROR);
      },
    });
  };

  const fetchWalletTransaction = () => {
    setLoading(true);
    request({
      url: apiPath.walletTransaction,
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        if (data?.statusText == "true") {
          setWalletTransactions(data?.data || []);
        } else {
          ShowToast(data?.message, Severty.ERROR);
        }
      },
      onError: (err) => {
        setLoading(false);
        ShowToast(err?.error?.response?.message, Severty.ERROR);
      },
    });
  };

  const getOrders = () => {
    setLoading(true);
    request({
      url: apiPath.listOrder,
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        if (data?.statusText == "true") {
          setOrders(data?.data);
        } else {
          ShowToast(data?.message, Severty.ERROR);
        }
      },
      onError: (err) => {
        ShowToast(err?.error?.response?.message, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    getOrders();
    let wallet = localStorage.getItem("wallet") || false;
    console.log(wallet, "-------wallet");
    fetchWalletInfo(wallet);
    fetchWalletTransaction();
    setPageHeading("Wallet");
  }, []);

  return (
    <>
      <div className="page-top-space home-card layout-content">
        <div className="mb-24">
          <Row gutter={[24, 16]}>
            <Col span={24} lg={24}>
              <Card className="home-card">
                <div className="wallet-items">
                  <div className="wallet-number">
                    <img src={WalletIcon} />
                    <div className="wlts-contant">
                      <p>Total Balance</p>
                      <h4>
                        {wallet?.balance?.toFixed(2) || "0"}{" "}
                        {wallet?.currency || "USD"}
                      </h4>
                    </div>
                    {otherWallets?.length > 0 && (
                      <div className=" ">
                        <img
                          onClick={() => setWalletModal(true)}
                          style={{ borderRadius: "20px" }}
                          width={30}
                          src={ExchangeIcon}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Card>
            </Col>

            <Col xs={24} className="sm-padding-0 ">
              <Tabs
                className="main_tabs"
                onTabClick={handleTabChange}
                activeKey={selectedTab}
                tabBarStyle={{ color: "green" }}
              >
                <TabPane className="tab-btn" tab="Order" key="order">
                  <Card className=" table-card">
                    <SectionWrapper
                      cardHeading={"Order History"}
                      className="section-tital"
                      // extra={
                      //   <>
                      //     <div className="w-100 text-head_right_cont">
                      //       <div className="pageHeadingSearch desh-input-search">
                      //         <Input.Search
                      //           className="searchInput"
                      //           placeholder={"search"}
                      //           // onChange={onSearch}
                      //           allowClear
                      //         />
                      //       </div>
                      //     </div>
                      //   </>
                      // }
                    >
                      <div className="table-responsive customPagination withOutSearilNo">
                        <Table
                          className="main-table"
                          loading={loading}
                          columns={columns}
                          dataSource={orders}
                          pagination={true}
                        />
                      </div>
                    </SectionWrapper>
                  </Card>
                </TabPane>

                <TabPane className="tab-btn" tab="Transaction" key="deposit">
                  <Card className=" table-card">
                    <SectionWrapper
                      cardHeading={"Transaction History"}
                      className="section-tital"
                      // extra={
                      //   <>
                      //     <div className="w-100 text-head_right_cont">
                      //       <div className="pageHeadingSearch desh-input-search">
                      //         <Input.Search
                      //           className="searchInput"
                      //           placeholder={lang("search")}
                      //           // onChange={onSearch}
                      //           allowClear
                      //         />
                      //       </div>
                      //     </div>
                      //   </>
                      // }
                    >
                      <div className="table-responsive customPagination withOutSearilNo">
                        <Table
                          className="main-table"
                          loading={loading}
                          columns={deposits}
                          dataSource={transactions}
                          pagination={true}
                        />
                      </div>
                    </SectionWrapper>
                  </Card>
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </div>
      </div>

      <Modal
        open={walletModal}
        width={300}
        onCancel={() => setWalletModal(false)}
        okText={"Add"}
        cancelText={"Cancel"}
        footer={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        title="Change Wallet"
        className="tab_modal "
      >
        <Form id="create" layout="vertical">
          <div className="price-n-text">
            <Select
              className="price-select"
              style={{ minWidth: "100%" }}
              onChange={(value) => {
                console.log(value);
                setSelectedWallet(value);
              }}
              placeholder="Select Wallet"
            >
              {otherWallets?.map((item, idx) => {
                return (
                  <Option key={idx} value={item?._id}>
                    {item?.currency}
                    {" - "}
                    {item?.balance?.toFixed(2)}
                  </Option>
                );
              })}
            </Select>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <Button
              style={{
                marginTop: 15,
                width: "50%",
                marginInline: "auto",
              }}
              onClick={() => {
                fetchWalletInfo(selectedWallet);
                setWalletModal(false);
              }}
              className="price-buy"
            >
              Confirm
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}

export default WalletIndex;
