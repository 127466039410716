import { CheckCircleOutlined } from "@ant-design/icons";
import { Form, Modal } from "antd";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";

const ConfirmOrder = ({ data, show, onOk, placeOrder, latestPrice, flag }) => {
  const countRef = useRef(1);
  const [done, setDone] = useState(false);

  useEffect(() => {
    const ids = [];
    if (!flag) {
      for (let i = 1; i < 60; i++) {
        ids.push(
          setTimeout(() => {
            if (i % 5 == 0) {
              // onOk();
              latestPrice();
              if (countRef.current) {
                countRef.current.innerHTML = `<h1>${5}</h1>`;
              }
              setDone(true);
            } else {
              console.log(countRef.current, " : ", i);
              if (countRef.current) {
                countRef.current.innerHTML = `<h1>${5 - (i % 5)}</h1>`;
              }
            }
          }, i * 1000)
        );
      }
    }

    return () => {
      ids.map((id) => {
        clearTimeout(id);
      });
    };
  }, [done]);

  console.log(data);
  return (
    <Modal
      open={show}
      onOk={onOk}
      onCancel={onOk}
      width={600}
      okText="Add"
      cancelText={null}
      footer={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      title=" Preview"
      className="tab_modal "
    >
      {!flag ? (
        <div className="count-box">
          <div ref={countRef} style={{ color: "white" }}>
            <h1>5</h1>
          </div>
        </div>
      ) : (
        <div className="count-box">
          <h1>
            <CheckCircleOutlined style={{ color: "lightgreen" }} />
          </h1>
          <p className="count-box-p">Order has been confirmed!</p>

        </div>
      )}
      <Form id="create" layout="vertical">
        <div className="price-n-text">
          <div className="qnty-price mt-0 mb-3">
            {data?.currency + " " + data?.bookAmount?.toFixed(2) + "/Oz"}
          </div>
        </div>
        <div className="buy-listing">
          <ul>
            <li>
              <span>Quantity (KG)</span>
              <p>{data?.quantity || "0"} KG</p>
            </li>
            <li>
              <span>Quality (Purity)</span>
              <p>{data?.quality || "995"}</p>
            </li>
            <li>
              <span>Purchased Date</span>
              <p>
                {moment(Date.now()).format("hh:mm A")}
                {", "}
                {moment(Date.now()).format("ll")}
              </p>
            </li>
            <li>
              <span style={{ fontWeight: "700", fontSize: "18px" }}>
                Total Amount
              </span>
              <p style={{ fontWeight: "700", fontSize: "18px" }}>
                {data?.currency}{" "}
                {(data?.quantity * data?.bookAmount * 32.148)?.toFixed(2)}
              </p>
            </li>
          </ul>
        </div>
        <div className="center-xy">
          {flag ? (
            <Button onClick={() => onOk()} style={{}} className="">
              Done
            </Button>
          ) : (
            <SlideToUnlock onConfirm={placeOrder} />
          )}
        </div>
      </Form>
    </Modal>
  );
};

const SlideToUnlock = ({ onConfirm }) => {
  const maxValue = 150; // The slider range maximum value
  const [currValue, setCurrValue] = useState(0);
  const [isUnlocked, setIsUnlocked] = useState(false);
  const inputRef = useRef(null);

  // Handle mouse/touch start
  const unlockStartHandler = () => {
    cancelAnimationFrame(inputRef.current.rafID);
    setCurrValue(Number(inputRef.current.value));
  };

  // Handle mouse/touch end
  const unlockEndHandler = () => {
    const value = Number(inputRef.current.value);
    setCurrValue(value);
    if (value >= maxValue) {
      successHandler();
    } else {
      inputRef.current.rafID = requestAnimationFrame(animateHandler);
    }
  };

  // Animate the slider back if not fully unlocked
  const animateHandler = () => {
    setCurrValue((prevValue) => {
      if (prevValue > 0) {
        inputRef.current.value = prevValue;
        inputRef.current.rafID = requestAnimationFrame(animateHandler);
        return prevValue - 2; // Slow down return animation
      } else {
        return 0;
      }
    });
  };

  // Handle unlock success
  const successHandler = () => {
    setIsUnlocked(true);
    onConfirm(); // Trigger confirm function when fully unlocked
    inputRef.current.value = 0; // Reset slider
    setCurrValue(0);
  };

  // Set slider attributes once component mounts
  useEffect(() => {
    inputRef.current.min = 0;
    inputRef.current.max = maxValue;
  }, []);

  return (
    <div className="center-xy">
      <div className="swipe-container">
        <input
          type="range"
          ref={inputRef}
          defaultValue={0}
          className="pullee"
          onMouseDown={unlockStartHandler}
          onMouseUp={unlockEndHandler}
          onTouchStart={unlockStartHandler}
          onTouchEnd={unlockEndHandler}
        />
        {isUnlocked ? <p>Swipe to Confirm</p> : <p>Confirmed</p>}
      </div>
    </div>
  );
};

export default ConfirmOrder;
