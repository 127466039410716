import { Card, Input, Table } from "antd";
import React, { useEffect, useState } from "react";
import SectionWrapper from "../../components/SectionWrapper";
import useRequest from "../../hooks/useRequest";
import apiPath from "../../constants/apiPath";
import { Severty, ShowToast } from "../../helper/toast";
import { useAppContext } from "../../context/AppContext";

const columns = [
  {
    title: "Currency",
    dataIndex: "id",
    key: "id",
    render: (_, { currency }) => <span color="white">{currency || "-"}</span>,
  },
  {
    title: "Buy Price",
    dataIndex: "buy_value",
    key: "buy_value",
    render: (_, { value }) => {
      return <span>{value?.toFixed(2)}</span>;
    },
  },
  {
    title: "Sell Price",
    dataIndex: "sell_value",
    key: "sell_value",
    render: (_, { value }) => {
      return <span>{value?.toFixed(2)}</span>;
    },
  },
];

function Index() {
  const [loading, setLoading] = useState(false);
  const { setPageHeading } = useAppContext();
  const [currencies, setCurrencies] = useState([]);
  const [tempCurrencies, setTempCurrencies] = useState([]);
  const { request } = useRequest();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [searchText, setSearchText] = useState("");

  const onSearch = (e) => {
    let value = e.target.value?.toUpperCase();
    if (value && value?.trim()) {
      const res = currencies.filter((cur) => {
        return cur["currency"]?.toString()?.includes(value);
      });
      setTempCurrencies(res);
      console.log(res);
    } else {
      setTempCurrencies(currencies);
    }
    setSearchText(value);
  };

  const latestCurrencies = (pagination, filters) => {
    setLoading(true);
    request({
      url: apiPath.latestPrice,
      method: "GET",
      onSuccess: ({ data, statusText, message }) => {
        console.log(data);
        if (statusText === "true") {
          let resp = Object.keys(data).map((cur) => {
            return { currency: cur, value: data[cur] };
          });
          setCurrencies(resp);
          setTempCurrencies(resp);
          setPagination((prev) => ({
            current: pagination?.current || prev.current,
            pageSize: pagination?.pageSize || prev.pageSize,
            total: resp.total,
          }));
        } else {
          ShowToast(message, Severty.ERROR);
        }
        setLoading(false);
      },
      onError: (err) => {
        setLoading(false);
        ShowToast(err?.message, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    latestCurrencies();
    setPageHeading("Available Currencies");
  }, []);

  return (
    <>
      <div className="page-top-space home-card layout-content">
        <div className="mb-24">
          <Card className=" table-card">
            <SectionWrapper
              cardHeading={"Available Currency"}
              className="section-tital"
              extra={
                <>
                  <div className="w-100 text-head_right_cont">
                    <div className="pageHeadingSearch desh-input-search">
                      <Input.Search
                        allowClear
                        className="searchInput"
                        size="large"
                        onChange={onSearch}
                        value={searchText}
                        onPressEnter={onSearch}
                        placeholder="Search Currency"
                        title="Search Currency"
                      />
                    </div>
                  </div>
                </>
              }
            >
              <div className="table-responsive customPagination withOutSearilNo">
                <Table
                  className="main-table"
                  loading={loading}
                  columns={columns}
                  dataSource={tempCurrencies}
                  pagination={{
                    defaultPageSize: 10,
                    responsive: true,
                    total: pagination.total,
                  }}
                  //   onChange={handleChange}
                />
              </div>
            </SectionWrapper>
          </Card>
        </div>
      </div>
    </>
  );
}

export default Index;
