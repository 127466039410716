import { Row, Col, Table } from "antd";
import React, { useEffect, useState } from "react";
import apiPath from "../../constants/apiPath";
import Flag from "react-world-flags";
import useRequest from "../../hooks/useRequest";

function CurrencyConvertor() {
  const { request } = useRequest();
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });

  const columns = [
    {
      title: "Currency/XAU",
      key: "currency",
      dataIndex: "currency",
      width: 500,
      render: (_, { currency }) => {
        let code = currency?.substring(0, 2);
        return (
          <>
            <Flag code={code} style={{ width: "32px", marginRight: "8px" }} />
            <span>{"XAU" + "-" + currency}</span>
          </>
        );
      },
    },
    {
      title: "Buy (XAU)",
      key: "buy",
      dataIndex: "buy",
      className: "buy-column",
      width: 200,
      render: (_, { value }) => {
        return <span>{value?.toFixed(2)}</span>;
      },
    },
    {
      title: "Sell (XAU)",
      key: "sell",
      dataIndex: "sell",
      width: 200,
      className: "sell-column",
      render: (_, { value }) => {
        return <span>{value?.toFixed(2)}</span>;
      },
    },
  ];

  const getForexData = () => {
    request({
      url: apiPath.latestPrice,
      method: "GET",
      onSuccess: (data) => {
        console.log(data);
        let resp1 = Object.keys(data?.data).filter((cur) => {
          return ["USD", "AED", "SGD", "HKD"].includes(cur);
        });
        let resp = resp1.map((cur) => {
          return { currency: cur, value: data?.data?.[cur] };
        });
        setData(resp);
        setPagination((prev) => {
          return {
            ...prev,
            page: 1,
            pageSize: 10,
            total: resp.length || 0,
          };
        });
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  useEffect(() => {
    getForexData();
  }, []);

  return (
    <>
      <Row gutter={32} className="align-items-center">
        <Col span={24} lg={12}>
          <div className="sladir-slaide rate-tabl">
            <h2 className="">
              <span>
                Currency <span className="mil-thin">Conversion</span>
              </span>
              Rates
            </h2>
            <p>
              {" "}
              With a strong emphasis on education and customer support, we
              provide resources to help our users make informed decisions and
              succeed in their trading journeys. Join us at Trading Platform and
              take control of your financial future.
            </p>
          </div>
        </Col>
        <Col span={24} lg={12}>
          <div className="app-container">
            <div className="table-responsive">
              <Table
                className="currency-table"
                columns={columns}
                dataSource={data}
                pagination={false}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default CurrencyConvertor;
